import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import Hero from "../components/home/hero"
import HomeServices from "../components/home/homeservices"
import Choose from "../components/home/choose"
import ClientList from "../components/home/clientlist"
import Cta from "../components/cta"
import Wave from "../components/wave"
import Seo from "../components/seo2"
import Banner from "../components/home/banner"
import "./index.scss"
import { getStaticContentByIdentifier } from "../services/misc.service";
import { getSEOByIdentifier } from "../services/seo.service";
import ProblemSolvers from '../components/home/problem-solvers'
import { getStaticImageByIdentifier } from '../services/image.service'

// TODO: Add to keystone
const heroGridItemContent = [
  {
      title: "Enterprise Software\n Development",
      anchor: "enterprisesoftwaredev",
  },
  {
      title: "Artificial Intelligence\n Platforms",
      anchor: "artificialintelligence",
  },
  {
      title: "Solutions Discovery &\n Design",
      anchor: "discoverydesign",
  },
  {
      title: "User Centric\n Design",
      anchor: "usercentricdesign",
  },
  {
      title: "Operations &\n Support",
      anchor: "opsandsupport",
  },
  {
      title: "Embedded Enterprise\n Consulting",
      anchor: "enterpriseconsulting",
  },
];

export default (props) => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  const chooseUss = props.pageContext.chooseUss;
  const serviceArray = props.pageContext.homeServices;
  const clientList = props.pageContext.clientList;
  const SEOInfo = props.pageContext.SEOInfo;

  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier('home', SEOInfo)}/>
      <Header />

      <main id="maincontent">
        <div className="home-hero-bg" style={{ backgroundImage: `url('${getStaticImageByIdentifier('home.hero.gradient.image', staticImages)}')` }}>
          <div className="grad-layer">
            <Hero staticContent={staticContent} staticImages={staticImages} heroGridItemContent={heroGridItemContent}/>
          </div>
        </div>
        <ProblemSolvers staticContent={staticContent} staticImages={staticImages}/>

        <div style={{ backgroundColor: '#f8f8f8' }} >
          <Wave color="#f8f8f8" background="#ffffff" />
          <Choose chooseUss={chooseUss} staticContent={staticContent} staticImages={staticImages}/>
        </div>

        <ClientList staticContent={staticContent} clientList={clientList}/>
        <Cta title={getStaticContentByIdentifier('home.CTA.content', staticContent)} button={getStaticContentByIdentifier('home.CTA.button', staticContent)} />
      </main>
      <Footer />
    </Layout>
  )
}
