import "./hero.scss";

import { Link } from "gatsby";
import React from "react";
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";
import heroImage from "../../../static/images/hero-image@2x.png";
import HeroGridItem from "./hero-grid-item";


export default (props) => {
    return (
        <section className="home-section">
            <img
                src={getStaticImageByIdentifier(
                    "home.hero.image.updated",
                    props.staticImages
                )}
                alt="Illustration of the Orange Bees logo"
                className="margin-t-6 margin-b-6"
            />
            <h1>
                {getStaticContentByIdentifier(
                    "home.hero.title.updated",
                    props.staticContent
                )}
            </h1>
            <div className="row margin-t-3">
                {props.heroGridItemContent.map((x, i) => (
                    <HeroGridItem title={x.title} anchor={x.anchor} key={i} />
                ))}
            </div>
        </section>
    );
    {
        /* <section id="home_top" className="margin-b-6 margin-t-6">
      <div className="row middle-md home-card">
        <div className="col-xs-12 col-md-6 margin-t-0">
          <h2 className="padding-l-3 padding-r-3">{getStaticContentByIdentifier('home.hero.title', props.staticContent)}</h2>
          <p className="padding-l-3 padding-r-3">{getStaticContentByIdentifier('home.hero.content', props.staticContent)}</p>
          <div className="hero-cta-container">
            <Link to="/services/" className="btn" activeClassName="active">
              {getStaticContentByIdentifier('home.hero.CTA', props.staticContent)}
            </Link>
          </div>
        </div>
        <div className="col-xs-0 col-sm-12 col-md-6 col-no-gutter">
          <img
            src={getStaticImageByIdentifier('home.hero.image', props.staticImages)}  
            alt="Illustration of two people working on a computer" 
            className="hero-illustration margin-t-6"
            />
        </div>
      </div>
    </section> */
    }
};
