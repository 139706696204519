import React from "react";
import { getStaticContentByIdentifier } from '../../services/misc.service';
import { getStaticImageByIdentifier } from "../../services/image.service";
import "./problem-solvers.scss";

export default function ProblemSolvers({ staticContent, staticImages }) {
    return (
        <div className="problem-solvers padding-6">
            <div className="problem-solvers-container">
                <h2 class="text">
                  {getStaticContentByIdentifier('problemsolvers.title', staticContent)}
                </h2>
                <img
                  src={getStaticImageByIdentifier('problem.solvers.image', staticImages)}
                  alt="Honeycomb shaped images of people working to solve problems together"
                />
            </div>
        </div>
    );
}
