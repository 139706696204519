import "./choose.scss"

import React from "react"
import { getStaticImageByIdentifier } from "../../services/image.service";
import { getStaticContentByIdentifier } from "../../services/misc.service";
import illustrationWhiteboard from '../../../static/images/illustrationWhiteboard.svg'

export default (props) => {
  return (
    <section className="choose-us padding-b-6 margin-b-6">
      <div className="row">
        <div className="col-xs-12 margin-t-4-xs margin-t-6-md margin-b-4 text-center">
          <h2>{getStaticContentByIdentifier('home.subtitle1', props.staticContent)}</h2>
        </div>
        <div className="row">
          {
            props.chooseUss.map((x, index) => (
              <div key={index} className="col-xs-12 col-sm-6">
                <div className={"choose-button"+index+" padding-t-1"}>
                  <div className="textbox">
                    <h3 className="text-uppercase">{x.title}</h3>
                    <p className="text-medium margin-t-2">{x.description}</p>
                  </div>
                  
                </div>
              </div>
            ))
          }
        <div className="choose-icon"></div>
        </div>
      </div>
    </section>
  )
}