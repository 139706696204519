import React from 'react';
import './hero-grid-item.scss';

export default function HeroGridItem({ title, anchor }) {
    return (
        <div className="hero-grid-item col-xs-6 col-md-4 text-center">
            <div className="title">{title}</div>
            <a href={`/services#${anchor}`}>Learn More</a>
        </div>
    )
}